import { Button } from "@mui/material";
import DialogStructure, { DialogStructureProps } from "../../../components/DialogStructure";
import PremiumFunctionalityState from "../components/PremiumFunctionalityState";


export interface PremiumFunctionalityDialogProps extends DialogStructureProps {
    onPremiumClick?: () => void;
}
export default function PremiumFunctionalityDialog({ onPremiumClick, ...props }: PremiumFunctionalityDialogProps) {

    return (
        <DialogStructure title="Funcionalidad PREMIUM" {...props}>
            <PremiumFunctionalityState mt={0} onPremiumClick={onPremiumClick} />
            <Button color="inherit" variant="outlined" sx={{ mt: 2 }} onClick={() => props.onClose?.({}, "backdropClick")} fullWidth>Prefiero continuar gratuitamente</Button>
            <Button color="inherit" variant="text" sx={{ mt: 2 }} onClick={onPremiumClick} fullWidth>Ya soy PREMIUM</Button>
        </DialogStructure>
    )
}